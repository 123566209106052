import React, { FC, Dispatch, SetStateAction } from 'react';
import Modal from '../Modal';

type UnsavedChangesProps = {
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  onConfirmCallback?: () => void;
  onCancelCallback?: () => void;
  onCloseCallback?: () => void;
};

const UnsavedChanges: FC<UnsavedChangesProps> = ({
  setShow,
  show,
  onConfirmCallback,
  onCancelCallback,
  onCloseCallback,
}) => {
  return (
    <Modal
      handleOnCancel={() => {
        setShow(false);
        onCancelCallback?.();
      }}
      handleOnClose={() => {
        setShow(false);
        onCloseCallback?.();
      }}
      handleOnConfirm={() => onConfirmCallback?.()}
      confirmlabel="Save changes"
      denyLabel="Leave without saving"
      visible={show}
      header="Unsaved Changes"
      headerIcon="save">
      <p>Are you sure you want to leave this page and discard your changes?</p>
    </Modal>
  );
};

export default UnsavedChanges;
